import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  padding: 0.125rem;
  height: 1rem;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 9999px;
`

const Inner = styled.div`
  height: 0.75rem;
  background: #48A0F4;
  border-radius: 9999px;
  transition: width 0.3s ease-in-out;
`

export default function ProgressBar({ progress, ...props }) {
  return (
    <Wrapper {...props}>
      <Inner style={{ width: `${(progress * 100)}%` }} />
    </Wrapper>
  )
}
