import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import GameButton from "../components/GameButton"
import GameResultPage from "../components/GameResultPage"
import NwHelmet from "../components/NwHelmet"
import Header from  "../components/HeaderSub"
import Question from "../components/quiz/Question"
import ProgressBar from "../components/quiz/ProgressBar"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

import hvLogo from "../images/hildingvilding_spion.svg"

const Quiz = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  background: linear-gradient(
    0deg,
    rgba(132, 201, 240, 0.3) 0%,
    rgb(132, 201, 240) 100%
  );
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  ${props => props.isStartPage && `
    display: flex;
    align-items: safe center;
    justify-content: center;
    align-self: center;
    flex-direction: column;
  `}
`

const Content = styled.div`
  width: 100%;
  padding: 1rem;
  overflow: hidden;

  ${props => props.isStartPage && `
    display: flex;
    align-items: safe center;
    justify-content: center;
    min-height: 100%;
  `}
`

const Inner = styled.div`
  max-width: 900px;
  margin: 0 auto;
`

const StartPage = styled.div`
  text-align: center;

  h1 {
    margin: 0 0 3.125rem;
    padding: 0 2.125rem;
    color: #3a3a3a;
    font-size: 1.875rem;
  }

  p {
    max-width: 50rem;
    margin: 0 auto 3.125rem;
    padding: 0 2.125rem;
    font-size: 1.5rem;
  }
`

const StyledProgressBar = styled(ProgressBar)`
  margin-top: 2rem;
  margin-bottom: 3.125rem;

  @media (min-width: 932px) {
    margin-bottom: 4.75rem;
  }
`

const StyledGameResultPage = styled(GameResultPage)`
  @media (min-width: 932px) {
    .column-2 {
      padding-top: 8.5rem;
    }
  }
`

export default function QuizTemplate({
  data: { quizYaml: quiz },
  pageContext: { lang, pagePath, lsKey, embed }
}) {
  const intl = useIntl()
  const defaultScore = 1000
  const [questionIndex, setQuestionIndex] = useState(-1)
  const [score, setScore] = useState(defaultScore)
  const [showResultPage, setShowResultPage] = useState(false)
  const activeQuestion = questionIndex > -1 ? quiz.questions[questionIndex] : null
  const { title, synopsis } = quiz
  const isStartPage = !activeQuestion && !showResultPage

  useEffect(() => {
    window.scroll(0, 0)
  }, [questionIndex])

  const handleContinue = () => {
    if (questionIndex === quiz.questions.length - 1) {
      setShowResultPage(true)
    }

    setQuestionIndex(questionIndex + 1)
  }

  const handleUpdateScore = (value) => {
    setScore(score + value)
  }

  const handleStartOver = () => {
    setShowResultPage(false)
    setQuestionIndex(0)
    setScore(defaultScore)
  }

  return (
    <Quiz>
      <Wrapper isStartPage={isStartPage}>
        {!embed && <Header lang={lang} title={title} room={6} roomSymbol={hvLogo} headingLevel="h2" />}
        <NwHelmet
          lang={lang}
          path={pagePath}
          title={title}
          image={{
            url: `/assets/share-images/NW_Delning8_HV_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
            width: 1200,
            height: 630,
          }}
          desc={synopsis}
          noIndex={embed}
        />
        <Content isStartPage={isStartPage}>
          {isStartPage &&
            <StartPage>
              <h1>{title}</h1>
              {synopsis && <p>{synopsis}</p>}
              <GameButton onClick={() => setQuestionIndex(0)}>{intl.formatMessage({ id: "start" })}</GameButton>
            </StartPage>
          }

          {(showResultPage || activeQuestion) &&
            <Inner>
              <StyledProgressBar progress={questionIndex / quiz.questions.length} />
            </Inner>
          }

          {!showResultPage && activeQuestion &&
            <Inner>
              <Question
                lang={lang}
                number={questionIndex + 1}
                question={activeQuestion}
                totalNumberOfQuestions={quiz.questions.length}
                updateScore={handleUpdateScore}
                onContinue={handleContinue}
                isLast={questionIndex + 1 === quiz.questions.length}
              />
            </Inner>
          }

          {showResultPage &&
            <Inner>
              <StyledGameResultPage
                lang={lang}
                score={score}
                maxScore={defaultScore}
                onTryAgain={handleStartOver}
                highscoreKey={lsKey}
              />
            </Inner>
          }
        </Content>
      </Wrapper>
      {!embed && (
        <>
          <BrowserUpdateMessage />
          <CookieBanner lang={lang} />
        </>
      )}
    </Quiz>
  )
}

export const pageQuery = graphql`
  query($lang: String, $_id: String) {
    quizYaml(lang: { eq: $lang }, _id: { eq: $_id }) {
      title
      synopsis
      questions {
        question {
          html
        }
        questionPic
        messageForCorrectAnswer
        answers {
          text {
            html
          }
          image
          correct
          messageForIncorrectAnswer
        }
      }
    }
  }
`
