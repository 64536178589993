import React, { useEffect, createRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.9);
  animation: show-feedback-message 0.3s forwards;

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
  }

  @keyframes show-feedback-message {
    from { transform: translateY(100%); }
    to { transform: translateY(0); }
  }

  @media (min-width: 932px) {
    display: flex;
    min-height: 8.75rem;
  }

  &:focus {
    outline: 0;
  }
`

const Inner = styled.div`
  max-width: 900px;
  margin: 0 auto;

  @media (min-width: 932px) {
    display: flex;
    width: 100%;

    ${props => props.isCorrect && `
      align-items: center;
    `}
  }
`

const MessageContainer = styled.div`
  width: 100%;
`

const MessageText = styled.p`
  margin: 0 0 2rem;
  font-size: 1rem;
  color: ${props => props.isCorrect ? "rgb(0, 120, 0)" : "#111"};

  @media (min-width: 932px) {
    width: 100%;
    margin-bottom: 0;
  }
`

const Button = styled.button`
  flex-shrink: 0;
  display: block;
  margin: 0 auto;
  padding: 0.75rem 1.5rem;
  border: 1px solid white;
  border-radius: 9999px;
  color: white;
  font: 1rem/1.1 ${props => props.theme.headingFont};
  white-space: nowrap;

  ${props => props.isCorrect ?
    `background: linear-gradient(180deg, rgba(0, 120, 0, 0.7) 0%, rgb(0, 100, 0) 100%);`
    :
    `background: linear-gradient(180deg, #B80000 0%, #E20000 100%);`
  }

  @media (min-width: 932px) {
    align-self: center;
    margin-left: 1rem;
  }
`

export default function FeedbackMessage({ message, isCorrect, onContinue, onTryAgain, isLast, ...props }) {
  const intl = useIntl()
  const ref = createRef()

  const messages = {
    incorrect: [
      intl.formatMessage({ id: "quizWrongAnswer1" }),
      intl.formatMessage({ id: "quizWrongAnswer2" }),
      intl.formatMessage({ id: "quizWrongAnswer3" }),
      intl.formatMessage({ id: "quizWrongAnswer4" }),
      intl.formatMessage({ id: "quizWrongAnswer5" }),
    ],
    correct: [
      intl.formatMessage({ id: "quizCorrectAnswer1" }),
      intl.formatMessage({ id: "quizCorrectAnswer2" }),
      intl.formatMessage({ id: "quizCorrectAnswer3" }),
      intl.formatMessage({ id: "quizCorrectAnswer4" }),
      intl.formatMessage({ id: "quizCorrectAnswer5" }),
    ],
  }

  const getRandomMessage = (key) => {
    const messageArray = messages[key]
    return messageArray[Math.floor(Math.random() * (messageArray.length - 1))]
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  })

  return (
    <Wrapper {...props} tabIndex="-1" ref={ref}>
      <Inner isCorrect={isCorrect}>
        <MessageContainer>
          <MessageText isCorrect={isCorrect}>
            {message || getRandomMessage(isCorrect ? "correct" : "incorrect")}
          </MessageText>
        </MessageContainer>
        {isCorrect ?
          <Button onClick={onContinue} isCorrect>
            {isLast ?
              intl.formatMessage({ id: "showResult" })
              :
              intl.formatMessage({ id: "nextQuestion" })
            }
          </Button>
          :
          <Button onClick={onTryAgain}>{intl.formatMessage({ id: "tryAgain" })}</Button>
        }
      </Inner>
    </Wrapper>
  )
}
