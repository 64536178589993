import React, { useState, useEffect, createRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import Answers from "./Answers"
import FeedbackMessage from "./FeedbackMessage"

const Wrapper = styled.div`
  :focus {
    outline: 0;
  }

  > p {
    text-align: center;
  }

  > button {
    display: block;
    width: auto;
    margin: 1rem auto;
    padding: 0.5rem 1rem;
  }
`

const QuestionNumber = styled.h2`
  margin: 0;
  padding-bottom: 1.5rem;
  font: 1.375rem/1.1 ${props => props.theme.headingFont};
  text-align: center;
  color: #111;

  @media (min-width: 932px) {
    text-align: left;
    padding-bottom: 2.25rem;
  }
`

const Content = styled.div`
  @media (min-width: 932px) {
    display: flex;
    justify-content: space-between;
  }
`

const QuestionWrapper = styled.div`
  @media (min-width: 932px) {
    width: 50%;
    flex-shrink: 0;
  }
`

const QuestionText = styled.p`
  margin: 0 0 3.125rem;
  padding: 0.5rem 0.875rem 0;
  font-size: 1.25rem;

  @media (min-width: 932px) {
    padding: 0;
  }
`

const QuestionPic = styled.img`
  display: block;
  width: calc(100% - 2rem);
  margin: 0 auto 1.25rem;
  border: 0.75rem solid white;
  background: white;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.125rem rgba(0, 0, 0, 0.25);

  @media (min-width: 932px) {
    width: 100%;
    margin-bottom: 1.875rem;
    border-width: 1rem;
  }
`

const StyledFeedbackMessage = styled(FeedbackMessage)`
  position: fixed;
  bottom: 0;
  left: 0;
`

export default function Question({ number, question, totalNumberOfQuestions, updateScore, onContinue, isLast }) {
  const intl = useIntl()
  const ref = createRef()
  const [usedAnswers, setUsedAnswers] = useState([])
  const [selectedAnswer, setSelectedAnswer] = useState(-1)
  const isCorrectAnswer = selectedAnswer > -1 && question.answers[selectedAnswer].correct
  const { questionPic, messageForCorrectAnswer, answers } = question

  const feedbackMessage = selectedAnswer > -1 ? (isCorrectAnswer ?
    messageForCorrectAnswer :
    answers[selectedAnswer].messageForIncorrectAnswer) :
    null

  useEffect(() => {
    if (selectedAnswer > -1 && !isCorrectAnswer) {
      updateScore(-50)
    }
  }, [selectedAnswer])

  useEffect(() => {
    ref.current.focus()
  }, [question])

  const handleTryAgain = () => {
    setUsedAnswers(usedAnswers.concat(selectedAnswer))
    setSelectedAnswer(-1)
  }

  const handleContinue = () => {
    setUsedAnswers([])
    setSelectedAnswer(-1)
    onContinue()
  }

  const isSvg = (file) => file.toLowerCase().endsWith(".svg")

  return (
    <Wrapper ref={ref} tabIndex="-1">
      <QuestionNumber>
        {intl.formatMessage({ id: "question" })} {number}
        <span className="structural">{intl.formatMessage({ id: "of" })} {totalNumberOfQuestions}</span>
      </QuestionNumber>
      <Content>
        <QuestionWrapper>
          {questionPic &&
            <QuestionPic src={questionPic + (isSvg(questionPic) ? "" : "?nf_resize=fit&w=843")} />
          }
          <QuestionText dangerouslySetInnerHTML={{ __html: get(question, "question.html") }} />
        </QuestionWrapper>
        <Answers
          answers={answers}
          setSelectedAnswer={setSelectedAnswer}
          selectedAnswer={selectedAnswer}
          usedAnswers={usedAnswers}
        />
      </Content>
      {selectedAnswer > -1 &&
        <StyledFeedbackMessage
          message={feedbackMessage}
          isCorrect={isCorrectAnswer}
          onContinue={handleContinue}
          onTryAgain={handleTryAgain}
          isLast={isLast}
        />
      }
    </Wrapper>
  )
}
