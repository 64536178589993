import React, { useEffect, createRef } from "react"
import styled from "styled-components"
import get from "lodash/get"

import whiteCross from "../../images/white-cross.svg"

const AnswerList = styled.ul`
  margin: 0;
  padding: 0 1rem;
  list-style: none;

  :focus {
    outline: 0;
  }

  li {
    position: relative;
    margin-bottom: 1rem;
  }

  ${props => props.imageAnswers && `
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: calc(50% - 0.625rem);
      margin-bottom: 1.5rem;
    }
  `}

  @media (min-width: 932px) {
    width: 43.5%;
    flex-shrink: 0;
  }
`

const AnswerButton = styled.button`
  position: relative;
  width: 100%;
  padding: 0;
  border: 0;
  background: none;
  color: black;

  ${props => props.longAnswer ? `
    font-size: 1rem;
    line-height: 1.25rem;
  ` : `
    > span {
      display: flex;
      font: 1.125rem/1.1 ${props.theme.headingFont};
    }
  `}

  ${props => props.imageAnswers && `
    > span {
      display: block;
      width: 100%;
    }
  `}

  &[disabled] {
    cursor: default;
  }

  ${props => props.isUsed && `
    &[disabled] > span {
      opacity: 0.5;
    }

    ${props => !props.imageAnswers && `
      span {
        color: white;
      }
    `}
  `}

  span,
  .text-span {
    transition: background 0.2s ease, box-shadow 0.2s ease;
  }

  &:hover:not([disabled]) .text-span,
  &:focus:not([disabled]) .text-span {
    background: #ABD6FF;
    box-shadow: 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);
  }

  ${props => (props.isCorrect || props.isIncorrect) && `
    .text-span {
      color: white;
      box-shadow: 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);
    }
  `}

  ${props => (props.isUsed || props.isIncorrect) && `
    .text-span {
      color: white;
      background: #B80000;
    }
  `}

  ${props => props.isCorrect && `
    .text-span {
      background: rgb(0, 134, 0);
    }
  `}
`

const cornerNumberStyle = `
  display: inline-block;
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid white;
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0.125rem;
`

const ImageNumber = styled.span`
  ${cornerNumberStyle}
  font: 0.875rem/1.2rem ${props => props.theme.headingFont};
`

const ImageText = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  font: 1rem/1.1 ${props => props.theme.headingFont};
  color: #333;
`

const Image = styled.span`
  display: block;
  width: 100%;
  height: 0;
  margin-bottom: 0.75rem;
  padding-top: 100%;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.125rem rgba(0, 0, 0, 0.25);
`

const buttonStyle = `
  display: inline-block;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid white;
  border-radius: 0.125rem;
`

const AnswerNumber = styled.span`
  ${props => props.longAnswer ? `
    ${cornerNumberStyle}
    font: 0.875rem/1.2rem ${props.theme.headingFont};
  ` : `
    ${buttonStyle}
    flex-shrink: 0;
    width: 1.875rem;
    padding: 0.875rem 0 0.625rem;
    margin-right: 0.4375rem;
  `}
`

const AnswerText = styled.div`
  ${buttonStyle}
  width: 100%;

  ${props => props.longAnswer ? `
    padding: 2rem;
    text-align: center;
  ` : `
    padding: 0.875rem 1rem 0.625rem;
    text-align: left;
  `}

  p {
    margin: 0;
  }
`

const Cross = styled.img`
  position: absolute;
  top: 2.3rem;
  left: 2.3rem;
  width: calc(100% - 4.6rem);
  filter: drop-shadow(0px 4px 2px rgb(184,0,0));
`

export default function Answers({ answers, setSelectedAnswer, selectedAnswer, usedAnswers }) {
  const ref = createRef()
  const answersWithId = answers.map((answer, index) => ({ ...answer, id: index }))
  const imageAnswers = !!answers[0].image
  const hasLongAnswers = answers.reduce((acc, curr) => (get(curr, "text.html", "").length > 25 ? true : acc), false)

  useEffect(() => {
    if (usedAnswers.length) {
      ref.current.focus()
    }
  }, [usedAnswers])

  return (
    <AnswerList ref={ref} tabIndex="-1" imageAnswers={imageAnswers}>
      {answersWithId.map((answer, index) => {
        const isUsed = usedAnswers.indexOf(index) > -1
        const isIncorrect = selectedAnswer === index && !answer.correct

        return (
          <li key={answer.id}>
            <AnswerButton
              onClick={() => setSelectedAnswer(index)}
              disabled={selectedAnswer > -1 || isUsed}
              isUsed={isUsed}
              isCorrect={selectedAnswer === index && answer.correct}
              isIncorrect={isIncorrect}
              imageAnswers={imageAnswers}
              longAnswer={hasLongAnswers}
              accessKey={index + 1}
            >
              {answer.image ?
                <span>
                  <ImageNumber aria-hidden="true">{index + 1}</ImageNumber>
                  <Image style={{ backgroundImage: `url(${answer.image})` }} />
                  <ImageText dangerouslySetInnerHTML={{ __html: get(answer, "text.html") }} />
                </span>
              :
                <span>
                  <AnswerNumber className={!hasLongAnswers ? "text-span" : ""} longAnswer={hasLongAnswers}>
                    {index + 1}
                  </AnswerNumber>
                  <AnswerText
                    className="text-span"
                    longAnswer={hasLongAnswers}
                    dangerouslySetInnerHTML={{ __html: get(answer, "text.html") }}
                  />
                </span>
              }
              {(answer.image && (isUsed || isIncorrect)) &&
                <Cross src={whiteCross} role="none" />
              }
            </AnswerButton>
          </li>
        )
      })}
    </AnswerList>
  )
}
